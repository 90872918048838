<template>
    <div>
        <v-data-table :headers="headers" :items="tbData" :single-expand="singleExpand" :search="search"
            :loading="loadingTb" loading-text="Sedang Memuat Data..." :expanded.sync="expanded" item-key="id">

             <template v-slot:item.noRow="{ item }" >
                 {{tbData.map(function(x) {return x.idKegiatanPmis; }).indexOf(item.idKegiatanPmis)+1}}
             </template>

        </v-data-table>

      

    </div>
</template>
<script>
    export default {
        data() {
            return {
                expanded: [],
                singleExpand: false,
                  headers: [
                    { text: 'No', value: 'noRow',sortable: false,width: "1%" },
                    { text: 'Kode Kegiatan', value: 'kodeKegiatan' },
                    { text: 'Nama Kegiatan', value: 'namaKegiatan' },
                    { text: 'Jumlah Paket', value: 'countPaket' },
                    { text: 'Pagu', value: 'Pagu' },
                    { text: 'Realisasi', value: 'realisasi' },
                    { text: 'Persentase Realisasi', value: 'realisasiPrs' },
                    { text: 'Persentase Realisasi Fisik', value: 'realisasiFisikPrs' },


                    
                ],
            }
        },
        mounted(){
           
        },
        methods: {
        
            getTable(){
                this.$emit('getTable');
            },
             edit(id){
                this.$emit('edit',id);
            },
             detail(id){
                this.$emit('detail',id);
            },
          
            
        },

        props: ['tbData', 'search', 'loadingTb'],
    }
</script>