import LayoutAdmin from '@/layouts/LayoutAdmin'
import myTable from './table'

export default {
  data() {
    return {
      search: '',
      loadingTb: false,
      tbData: [],
      getData: [],
      setId: null,
      listProject: [],
      isShow:false,
      isActive: [],
      tanggalEmon:null,
      tanggalPmis:null,
      listDataPaket:[],
      loadingTbPaket:false,
      selectedPaket:[],
      setkodeKegiatan:null,
      loadingListPaketMapping:false,
      listPaketMapping:[],
      listDisabled:[],
      listImed:[],
      paketValue:[],
      titleNamaKegiatan:null,
      loadingMonitor:false,
      tbDataMonitor:[]
    }
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {
    // this.getTable()

    this.G_GetRef('proyek').then(data => {
      this.listProject = data;
    });

   
  },
  components: {
    myTable
  },
  methods: {
    getTable() {
      this.isShow = true
      this.loadingTb = true
      this.loadingMonitor = true

      let filter = ''
      if (this.$refs.filter_project.getValue()) {
        filter = this.$refs.filter_project.getValue()
      }
      

      this.G_GetAny('pmis/realisasi?projekId='+filter).then(res => {
        this.tanggalEmon = res.data.tanggalEmon
        this.tanggalPmis = res.data.tanggalPmis

        this.loadingTb = false
        this.tbData = res.data.content

      })

      this.G_GetAny('proyek/monitoring?proyek='+filter).then(res => {
       
      
        this.loadingMonitor = false
        this.tbDataMonitor = res.data[0]

        console.log(this.tbDataMonitor);
      })

      // this.G_GetAny('progres/per-paket/nested?hasMapping=false&proyek='+filter).then(res => {
      
      //   this.listDataPaket = res.data.content

      //   this.$refs.modal.loadingEnd()
      // })

      // this.G_GetAny('progres/per-paket/nested').then(res => {
      //   console.table(res)
      // })

     

    },
    toggleSub(key) {
     
      this.isActive[key] = !this.isActive[key];

      if (this.isActive[key]) {
        $("#sub_" + key).hide();
      } else {
        $("#sub_" + key).show();
      }

    },
    loadPMIS(){

      if (this.$refs.filter_project.getValue()) {
        let formData = new FormData();
        formData.append('projekId',this.$refs.filter_project.getValue())

        this.G_PostAny('pmis/load', formData).then(res => {
          if (!res.status) {
            this.getTable();
            this.$snotify.success('Berhasil Load Data PMIS');
          }else{
            this.$snotify.warning('Terjadi Kesalahan');
          }
        })
        .finally(() => {
        
        });
      }else{
        this.$snotify.warning('Silahkan Pilih Project Terlebih Dahulu');
      }      
    },
    mappingPMIS(kodeKegiatan,namaKegiatan){


      this.selectedPaket = []
      this.listDisabled = []
      this.listImed = []
      this.paketValue = []
      this.setkodeKegiatan = kodeKegiatan
      this.titleNamaKegiatan = namaKegiatan
      this.$refs.modal.open("Mapping PMIS", '90%')
   
      this.G_GetAny('progres/per-paket/nested?hasMapping=false&includePmis=true&idKegiatanPmis='+kodeKegiatan).then(res => {
        let tmpKegiatan = res.data.content;

        tmpKegiatan.forEach(v1 => {
          v1.childs.forEach(v2 => {
            v2.childs.forEach(v3 => {
              v3.childs.forEach(v4 => {
                v4.childs.forEach(v5 => {

                  if (v5.idKegiatanPmis == kodeKegiatan) {
                    this.selectedPaket.push(v5.kode)
                    this.listDisabled[v5.kode] = false
                    this.listImed[v5.kode] = false
                  }
                  else if(v5.idKegiatanPmis != kodeKegiatan){
                    this.selectedPaket.push(v5.kode)
                    this.selectedPaket.pop();
                    this.listImed[v5.kode] = true
                    this.listDisabled[v5.kode] = true

                    
                  }
                  else{
                    // this.selectedPaket.push(v5.kode)
                    // this.listDisabled[v5.kode] = true

                  }

                });
              }); 
            });
          });
        });
        
      })
    

    },
    MappingPMISAct(){

      this.$refs.modal.loading()

      let data = {
        "idKegiatanPmis":this.setkodeKegiatan,
        "kodePaket":this.selectedPaket
    };

      this.G_PostAnyCustom('pmis/mapping', data).then(res => {
        if (!res.status) {
          this.$refs.modal.close()
          this.$snotify.success('Berhasil Melakukan Mapping');
        }else{
          this.$snotify.warning('Terjadi Kesalahan');
        }
      })
      .finally(() => {
       this.$refs.modal.loadingEnd()
      });
    },
    mappingPMISList(kodeKegiatan,namaKegiatan){

      this.titleNamaKegiatan = namaKegiatan

      this.$refs.modalPaket.open("List Paket Mapping ", '90%')

      this.$refs.modalPaket.loading()

      this.G_GetAny('progres/per-paket/nested?hasMapping=true&includePmis=true&idKegiatanPmis='+kodeKegiatan).then(res => {
       
        this.listPaketMapping = res.data.content
        this.$refs.modalPaket.loadingEnd()
      })
      
      
    }
    
    
  
  }
}